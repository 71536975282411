:root {
  --PrimaryButtonBg_firstColor: #0354fe;
  --PrimaryButtonBg_secondColor: #13a9fa;
  --PrimaryButtonBg: linear-gradient(
    157deg,
    var(--PrimaryButtonBg_firstColor),
    var(--PrimaryButtonBg_secondColor)
  );
  --PrimaryButtonHoverBg: linear-gradient(
    157deg,
    var(--PrimaryButtonBg_secondColor),
    var(--PrimaryButtonBg_secondColor)
  );
  --buttonLoadingBg: linear-gradient(
    157deg,
    var(--PrimaryButtonBg_firstColor),
    var(--PrimaryButtonBg_firstColor)
  );
  --buttonSuccessBg: linear-gradient(
    157deg,
    var(--successButtonBg),
    var(--successButtonBg)
  );
  --buttonErrorBg: linear-gradient(
    157deg,
    var(--errorButtonBg),
    var(--errorButtonBg)
  );
  --defaultButtonBg: transparent;
  --defaultButtonColor: #242c41;
  --successButtonBg: #00b17a;
  --errorButtonBg: #d00027;
  --inputFocusColor: #5481f7;
  --inputColor: #242c41;
  --danger: #e76b80;
}
.uButton {
  font-family: "Ubuntu";
  outline: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 158px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 14px 21px;
  min-height: 44px;
  position: relative;
  user-select: none;
  text-decoration: none;
  letter-spacing: 0.6px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  box-shadow: 3px 3px 4px 0 rgba(36, 44, 65, 0.19);
  display: inline-flex;
}
.uButton:before {
  border-radius: inherit;
  background-image: linear-gradient(#ccc, #fff);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  transition: opacity 0.45s;
}

.uButton:focus,
.uButton:active {
  outline: none;
}

.uButton .buttontext {
  display: block;
  position: relative;
}
.uButton.loading .buttontext,
.uButton.success .buttontext,
.uButton.error .buttontext {
  opacity: 0;
  transform: translateX(-2px);
  transition-duration: 0.2s;
}

.uButton .icon {
  width: 44px;
  height: 44px;
  display: block;
  border-radius: 50%;
  background-size: contain;
  position: absolute;
  z-index: 2;
  top: 0;
}
.Hamburger_Menu .uButton .icon {
  position: unset;
}

.uButton.success .icon {
  background-image: url("/icons/button-check.svg");
}

.uButton.error .icon {
  background-image: url("/icons/icon__cross.svg");
  width: 30px;
  height: 30px;
  top: unset;
}

.uButton.loading .icon {
  background-image: url("/icons/button-loading.svg");
  animation: loadingIcon cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s infinite;
}

@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uButton.default {
  background: var(--defaultButtonBg);
  color: var(--defaultButtonColor);
  border-radius: 0;
  justify-content: center;
  font-family: "Ubuntu";
  box-shadow: unset;
  box-shadow: none;
}
.uButton.Button__Section {
  color: #caced5;
}

.uButton.default .icon {
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 30px;
}

.uButton.default:hover,
.uButton.link:hover {
  text-decoration: none;
  opacity: 0.5;
}

.uButton.Primary:before {
  background-image: var(--PrimaryButtonHoverBg);
}
.uButton.Primary {
  background: var(--PrimaryButtonBg);
}

.uButton.Primary:hover:before {
  opacity: 1;
}

.uButton.checked:hover {
  background: var(--successButtonBg);
}

.uButton.success:before {
  background-image: var(--buttonSuccessBg);
  opacity: 1;
}
.uButton.error:before {
  background-image: var(--buttonErrorBg);
  opacity: 1;
}
.uButton.loading:before {
  background-image: var(--buttonLoadingBg);
  opacity: 1;
}
