#Login {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .Logo{
        margin-top: 20px;
    }
    .card{
        height: min-content;
        margin: auto;
    }
}