.error-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    &-card {
        width: max(40%, 300px);
        &-error {
            margin: 0;
        }
    }
}
